<template>
    <div class="mt-9 w-full">
        <form class="w-full">
            <RoundDesc class="m-4" name="账号　　" divide >
                <input class="self-stretch flex-1 w-0" type="text" v-model="data.username" placeholder="请输入账号" />
            </RoundDesc>
            <PasswordInputCell label="密码　　" placeholder="请输入密码" v-model="data.password" />
        </form>
        <div class="px-6 flex justify-between">
            <f7Link class="text-[var(--f7-label-text-color)]" href="/retrieve">忘记密码</f7Link>
            <f7Link class="text-[var(--f7-label-text-color)]" @click="emit('switch', 'phone')">手机快捷登录
                <img v-if="login.versions === 'ZanFu'" class="w-6 h-6" src="./assets/right-arrow-blue.svg" alt="">
                <img v-else class="w-6 h-6" src="./assets/right-arrow.svg" alt=""></f7Link>
            <!-- <f7Link class="text-[var(--f7-label-text-color)]" href="/register">去注册</f7Link> -->
        </div>

        <ActionButton fill class="m-4 mt-8" :action="loginAction">登录</ActionButton>
        <div class="px-6 flex justify-center" v-if="login.invitationCode">
            <f7Link fill class="text-[var(--f7-label-text-color)] sign" href="/register">没有账号? 去注册</f7Link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { f7Link,f7 } from 'framework7-vue';
import {getColorTheme , checkOpenChannel} from '../api'
import RoundDesc from 'src/components/RoundControls/RoundDesc.vue';
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import PasswordInputCell from 'src/pages/登录/components/PasswordInputCell/index.vue';
import { onMounted, reactive } from 'vue';
import type { LoginType } from './index.vue';
import { makeLoader } from 'src/Loader';
import * as api from '../api'
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import { getCurrentInstance } from 'vue'
const instance:any = getCurrentInstance();

const data = reactive({
    pwdVisibility: false,
    username: '',
    password: '',
    code: ''

});

const login = sharedLogin

const emit = defineEmits<{
    (e: 'login'): void
    (e: 'switch', value: LoginType): void
}>()

const loader = makeLoader(api.login)
    .onLoad(login.setLogin.bind(login))
    .showError()
    .showSuccess('登录成功')
    .reactive()

function loginAction() {
    return login.askAgreePrivacyIfNeeds()
        .then(() => loader.load(data.username, data.password,login.code(),'H5',login.appId).then(()=>{
            showPackageEntrance();
            if(login.versions === 'ZanFu'){
                f7.setColorTheme('#5575FF')
                localStorage.setItem("color1",JSON.stringify('#5575FF'));
            }else{
                themeColor();
            }
            getOpenChannel();
            // if(login.code()){
            //     getOpenChannel();
            // }
        }))
}

const showPackageEntrance = async()=>{
    let res = await api.showPackageEntrance();
    localStorage.setItem("isDefault",JSON.stringify(res))
    let isDefault = res.isDefault ? 1 : 0
    instance?.proxy?.$bus.emit('isDefault',isDefault)
}

const themeColor = async() => {
    let res = await getColorTheme({'type':1});
    f7.setColorTheme(res.color);
    localStorage.setItem("color1",JSON.stringify(res.color));
}

const getOpenChannel = async()=>{
    let params = {'code':login.invitationCode}
    let res = await checkOpenChannel(params);
    let pageType = '-1';
    if(res.openAccount){
        if(!res.auditChannel){  
            pageType = '0';
        }else{
            pageType = res.auditChannel;    //-1首页 0选择通道  1支付宝 2微信 3京东 4信用卡 5扫呗 8富友 
        }
    }else{
        pageType = '-1';
    }
    instance?.proxy?.$bus.emit('pageType',pageType)
}

onMounted(()=>{
    showPackageEntrance();
    getOpenChannel();
    if(login.versions === 'ZanFu'){
        f7.setColorTheme('#5575FF')
    }else{
        if(localStorage.getItem("color1") && localStorage.getItem("color1") != '' && localStorage.getItem("color1") != '#5575FF' ){
            let color:any = localStorage.getItem("color1")
            let color2 = JSON.parse(color)
            f7.setColorTheme(color2)
        }else{
            f7.setColorTheme('#DB3022')
        }
    }
})

</script>

<style scoped>
.sign{
    text-align: center;
    text-decoration: underline;
    color:var(--f7-theme-color) ;
    margin-top: 8px;
}
</style>
