<template>
    <div>
        <RoundCard  style="border-radius: 10px 10px 0 0;">
            <RoundCardHeader title="请选择分期银行"></RoundCardHeader>
            <div style="height: 80vh;overflow: hidden;overflow-y: auto;">
                <!-- 搜索 -->
                <div class="headerInput w-full">
                    <div class="flex">
                        <div class="icon-inp">
                            <img class="w-[12px]" src="../../../首页/assets/search.svg" alt="">
                        </div>
                        <f7-input class="text-input" label="搜索" type="text" placeholder="请输入银行名称"  v-model:value="searchBankName" @input:clear="searchBankList" @change="searchBankList" clear-button>
                            <template v-slot:media>
                            <f7-icon icon="demo-list-icon"></f7-icon>
                            </template>
                        </f7-input>
                        <div class="input-right text-inp" @click="searchBankList">&nbsp;|&nbsp;搜索</div>
                    </div>
                </div>
                <div class="content-bank" v-for="item in copyBankList" @click="selectBank(item)">
                    <span>{{ item.bankName }}</span>
                    <div class="flex">
                        <span v-if="newSplice(item.discounts,item.bankDiscounts)?.length > 1" class="down" @click.stop="item.down = !item.down">{{ item.down ? '收起' : '更多'}}</span>
                        <div v-if="newSplice(item.discounts,item.bankDiscounts)?.length > 1" class="arrows" :style="{transform : item.down ? 'rotate(270deg)' : 'rotate(90deg)'}"></div>
                        <div>
                            <template v-for="(n,index) in newSplice(item.discounts,item.bankDiscounts)">
                                <div class="content-bank-right" :class="[n.flag == 2 ? 'green' : '']" @click.stop="item.down = !item.down" v-if="index < 1 || item.down">{{ n.title }}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </RoundCard>
   </div>
</template>

<script setup lang="ts">
import { f7Input } from 'framework7-vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import { ref } from 'vue';

export type bankType = {
    bankCode: string
    rate: string
    bankName: string
    discounts: string[]
    bankDiscounts?: string[]
    down:boolean
}

const props = defineProps<{
    bankList:bankType[]
}>();

const emit = defineEmits<{
    (event: 'bankSelect', value?: string): void
}>();


const selectBank=(item:any)=>{
    searchBankName.value = "";
    emit('bankSelect',item);
}

const searchBankName = ref('');
const columns = ref(props.bankList);
const copyBankList = ref(props.bankList);

//银行搜索
const searchBankList=()=>{
    let list = []
    for(let i = 0;i<columns.value.length;i++){
        if(columns.value[i].bankName.includes(searchBankName.value)){
            list.push(columns.value[i]);
        }
    }
    copyBankList.value = list;
}

const newSplice = (item1:any,item2:any)=>{
    let data:any = []
    if(item1){
        item1.forEach((item:any) => {
            data.push({'title':item,'flag':1})
        });
    }
    if(item2){
        item2.forEach((item:any) => {
            data.push({'title':item,'flag':2})
        });
    }
    return data
}


defineExpose({
    
})


</script>

<style scoped>
.headerInput{
    height: 48px;
    padding: 8px 16px 8px 16px;
    background-color: #FFFFFF;
}
.icon-inp{
    display: flex;
    justify-content: space-evenly;
    background-color: #f8f8f8;
    border-radius: 8px 0 0 8px;
    width: 28px;
}
.text-input{
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: space-between;
    background-color: #f8f8f8;
}
.input-right{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: var(--f7-theme-color);
    background-color: #f8f8f8;
    width: 84px;
    border-radius: 0 8px 8px 0;
}
.content-bank{
    font-family: PingFang SC;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.8px;
    padding: 16px;
    border-bottom: 1px solid #E6EBF2;
    text-align: left;
    color: #081828
}
.content-bank-right:last-child{
    margin-bottom: 0;
}
.content-bank-right{
    color: var(--f7-theme-color);
    border: 1px solid var(--f7-theme-color);
    border-radius: 4px;
    padding: 3px 8px;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    margin-bottom: 8px;
}
.down{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 24.8px;
    height: 24.8px;
    margin-right: 4px;
    text-align: center;
    color: var(--f7-theme-color)
}
.arrows{
    height: 10px;
    margin-top: 8px;
    margin-right: 8px;
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../../../收款/活动收款/assets/arrows.svg);
    margin: auto;
    transform: rotate(90deg);
}

.green{
    color: #c5742b;
    border: 1px solid #c5742b;
}
</style>
