<template>
    <Page v-bind="$props" title="手动金额收款" navbar :tab-root="role == 3 || role == 6 ? false : isDefault ? true : false">
        <div class="box">
            <Header :key="data.params.payChannelType" :list="list" :payChannelType="data.params.payChannelType" text="切换固定收款" @HeaderBarEmit="toCollection()" @HeaderPayChannelSelect="switchPayChannel"></Header>
            <div class="flex justify-between mx-4 mt-4 goCollection" v-if="isDefault ? true : false">
                <div>返回旧版页面收款</div>
                <div class="flex items-center" style="color: var(--f7-theme-color);" @click="toOldCollection()">请点击&nbsp;
                <div class="icon-cell"></div>
                </div>
            </div>
            <RoundCard class="mx-4 mt-4">

            <Tabs leftText="用户实付" rightText="商户实收" active="0" @tabsEmit="onmyclick"></Tabs>
            <!-- <RoundCardHeader v-else title="收款信息"></RoundCardHeader> -->

            <div class="p-4">
                <div class="flex justify-between items-center mb-3">
                    {{ activeIndex === '0' ? "用户实付金额": "商户实收金额"}} 
                    <span class="flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <span class="mr-1">￥</span>
                        <input class="h-8 w-[120px]" type="number" min="0" placeholder="请输入收款金额" v-model="data.chooseMeal.money"
                            @blur="data.amount = data.chooseMeal.money">
                    </span>
                </div>
                <div class="flex justify-between items-center ">
                    <span class="flex-none">分期期数</span>
                    <SelectStyle>
                        <select class="w-[130px]" v-model="data.num">
                            <option :value="'请选择'" selected>请选择</option>
                            <template v-for="num in data.chooseChannelObj.numList">
                                <option v-if="num.show" :value="num">{{ num.value }}</option>
                            </template>
                        </select>
                    </SelectStyle>
                </div>
                <div v-if="data.params.payChannelType === 'LIANLIAN' && data.num != '请选择'" class="flex justify-between items-center mt-3">
                    <span class="flex-none">分期银行</span>
                    <SelectStyle>
                        <div class="w-[130px] truncate simSelect" @click="isOpenedBank()">
                            {{ data.bankCode ? bankName : '请选择' }}
                        </div>
                    </SelectStyle>
                </div>
                <div v-if="data.num != '请选择' && showDiscount " class="flex justify-between items-center mt-3">
                    <span class="flex-none">优惠金额</span>
                    <div class="w-[130px] truncate">
                        ￥{{ discountAmount }}
                    </div>
                </div>
                
                <div v-show="data.num && data.num.isFeeSeller" class="flex justify-between items-center mt-3">
                        <span class="flex-none" ref="tooltip">是否商贴 <img class="inline align-text-bottom"
                                src="./assets/question.svg" alt=""></span>
                        <f7Toggle v-model:checked="data.feeType" :disabled="feeTypeSwitch == 0"></f7Toggle>
                    </div>
            </div>
            </RoundCard>
            <RoundCard class="mx-4 mt-4 overflow-hidden">
                <RoundCardHeader title="其他信息"></RoundCardHeader>
                <div class="flex justify-between items-center p-3">
                    <span class="flex-none">客户姓名<span v-if="requireInfo.customerName == 1" style="color: red;">*</span></span>
                    <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入客户姓名" v-model="data.customerName">
                    </span>
                </div>
                <div class="flex justify-between items-center mb-3 px-3">
                    <span class="flex-none">客户手机号<span v-if="requireInfo.customerPhone == 1 || data.mode == 1" style="color: red;">*</span></span>
                    <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入客户手机号" v-model="data.customerPhone">
                    </span>
                </div>
                <div class="flex justify-between items-center mb-3 px-3">
                    <span class="flex-none">营业员工号<span v-if="requireInfo.outCashierNo == 1 " style="color: red;">*</span></span>
                    <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入营业员工号" v-model="data.outCashierNo">
                    </span>
                </div>
                <div class="flex justify-between items-center mb-3 px-3">
                    <span class="flex-none">商品品牌<span v-if="requireInfo?.productName == 1" style="color: red;">*</span></span>
                    <span class="w-[190px] border-b border-solid border-[#DFDFDFCC]">
                    <SelectStyle class="w-[180px] float-right">
                        <select class="w-[180px]" style="border: none;text-align: right !important;" v-model="data.productName">
                            <option :value="''">请选择</option>
                            <template v-for="item in data.productNameList">
                                <option :value="item">{{ item }}</option>
                            </template>
                        </select>
                    </SelectStyle>
                    </span>
                </div>
                <div class="flex justify-between items-center mb-3 px-3">
                    <span class="flex-none">商品型号<span v-if="requireInfo?.productSpec == 1" style="color: red;">*</span></span>
                    <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入商品型号" v-model="data.productSpec">
                    </span>
                </div>
                <div class="flex justify-between items-center mb-3 px-3">
                    <span class="flex-none">IMEI<span v-if="requireInfo.imei == 1 " style="color: red;">*</span></span>
                    <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                        <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入IMEI" v-model="data.imei">
                        <IMEIInput class="ml-2" v-model:imei="data.imei"></IMEIInput>
                    </span>
                </div>
            </RoundCard>
            <div style="background-color: #f8f8f8;width: 100%;height: 20px;"></div>
            
        <TabBar @TabBarEmit="qrcollect()" :totalAmount="data.calculate.totalAmount" :sellerAmount="data.calculate.sellerAmount"></TabBar>
        </div>
        <f7Actions v-model:opened="isOpened">
            <BankIsOpened v-if="isOpened" :bankList="bankList" @bankSelect="selectBank"></BankIsOpened>
        </f7Actions>
    </Page>
</template>

<script async setup lang="ts">
import { Router } from 'framework7/types';
import { f7Toggle , f7Actions , f7Input } from 'framework7-vue';
import PlainSelect from 'src/components/Inputs/PlainSelect.vue';
import Page from 'src/components/Pages/Page.vue';
import { onMounted , reactive , ref , watch } from 'vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import SelectStyle from 'src/components/Inputs/BoardSelect.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import IMEIInput from '../../收款/收款内容/IMEIInput.vue';
import { getPayChannelTypeList , moneyCompute  , activityIndexPage , getBankList , activityCompute , getCustomerInfoConfig } from "../api";
import * as orderApi from '../../订单/api';
import * as api from '../api';
import { makeLoader } from 'src/Loader';
import { forEach } from 'lodash';
import Tabs from './components/tabs.vue';
import TabBar from './components/tabBar.vue';
import Header from './components/header.vue';
import BankIsOpened from './components/bankList.vue'
const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
    // collection:string
}>();

const role = ref(JSON.parse(localStorage.getItem("user"))?.accountType)
const isDefault = ref(JSON.parse(localStorage.getItem("isDefault"))?.isDefault)
//是否展示入口
const getShowPackageEntrance =()=>{
  api.showPackageEntrance().then((res)=>{
    isDefault.value = res.isDefault;
  })
}

const data:any = reactive({
   productNameList:['华为（HUAWEI）',
  '苹果（Apple）',
  '荣耀（HONOR）',
  'OPPO','vivo','小米（XIAOMI）','红米（Redmi）',
  '魅族（MEIZU）','一加（ONEPLUS）','真我（realme）',
  '中兴（ZTE）','IQOO','努比亚（nubia）','红魔（Redmagic）','Hi nova','其他品牌（商品型号填写)'],
  params:{},
  selections:[],

  chooseChannelObj:{},
  num:'请选择',
  bankCode:undefined,
  provinceCode:'',
  mode:'',
  customerName:'',
  customerPhone:'',
  outCashierNo:'',
  productName:'',
  productSpec:'',
  imei:'',
  isFeeSeller:'0',
  feeType:false,
  chooseMeal:{id:''},                            //选中套餐
  calculate:{}                                   //测算金额
})


watch(() => data.params.payChannelType, item => {        //切换通道
    data.chooseChannelObj = data.selections.payInfo.find((item: { key: string; }) => item.key == data.params.payChannelType );
    data.chooseMeal.payeeKey = 'BAL';
    data.mode = undefined
})

watch(() => data?.num, item => {                                   //切换期数
    // if(data.params.payChannelType == 'LIANLIAN'){
        data.bankCode = undefined;
        data.provinceCode = undefined;
        data.mode = undefined;
        // setBankList();
    // }
    data.chooseMeal.fqNum =  data.num?.key;
    // setMoneyCompute();
    setActivityCompute();
})

watch(() => data.feeType , item => {                            //切换商贴
    if(data.feeType){
        data.isFeeSeller = 1
    }else{
        data.isFeeSeller = 0
    }
    data.bankCode = undefined;
    data.provinceCode = undefined;
    data.mode = undefined;
    setActivityCompute(true);
})


watch(() => data?.bankCode, item => {                         //切换银行
    data.chooseMeal.bankCode = data?.bankCode;
    setActivityCompute(true);
})

watch(() => data.chooseMeal.money, item => {                         //金额
    if(data.chooseMeal.money<0){
        data.chooseMeal.money = 0
    }
    // if(data.params.payChannelType == 'LIANLIAN'){
        data.bankCode = undefined;
        data.provinceCode = undefined;
        data.mode = undefined;
        // setBankList();
    // }
    setActivityCompute(true);
})

//切换通道
const switchPayChannel=(e:string)=>{
    data.params.payChannelType = e;
    // if(e != "LIANLIAN"){
    //     activeIndex.value = '0';
    // }
    // if(e == "LIANLIAN"){
    //     data.isFeeSeller = '1'
    // }else if(activeIndex.value == '0'){
    //     data.isFeeSeller = '0'
    // }
    data.calculate = {};
    data.chooseMeal = {};
    data.num = '请选择';
}


const qr = makeLoader(orderApi.qrcollection)
  .mapParams((confirmId) => [{
    confirmId,
    'payKey': data.params.payChannelType,
    'payeeKey': 'BAL',
    'num': data.num?.key,
    'isFeeSeller': data.isFeeSeller,
    'amount': data.calculate.tradeAmount,
    'bankCode':data?.bankCode,
    'provinceCode':data?.provinceCode,
    'mode':data?.mode,
    'customerName':data.customerName,
    'customerPhone':data.customerPhone,
    'outCashierNo':data.outCashierNo,
    'productName':data.productName,
    'productSpec':data.productSpec,
    'imei':data.imei,
    'calType':activeIndex?.value === '0' ?  '1' : activeIndex?.value === '1' ? '2' : '',
    'discountAmount':discountAmount.value
  }])
  .withIndicator()
  .showError()


  async function qrcollect(confirmId?:any) {
    if(!usable.value){
        showError('不支持该收款方式');
        return;
    }
    if(!data.calculate.enable){
        showError(data.calculate.errMsg);
        return;
    }
    // if((data.requireOutCashierNo == 'true' || data.mode == 1) && !data.customerPhone){
    //     showError('请输入手机号');
    //     return;
    // }
    // if(data.requireOutCashierNo == 'true' && !data.outCashierNo){
    //     showError('请输入营业员工号');
    //     return;
    // }
  const { tradeNo , riskInfo }:any = await qr.load(confirmId)
  if(isEmpty(riskInfo)){
    props.f7router.navigate(`/collection/${tradeNo}`, { props: { redCodeParams: '' } })
    return;
  }
  if( !riskInfo.pass ){
    showError(riskInfo.riskMsg);
    return 
  }else{
    if( riskInfo.confirm ){
      window.f7.dialog.create({
        title: '风控提示',
        text: riskInfo.riskMsg,
        buttons: [
            {
              text: '取消',
              onClick(dialog, e) {
              },
              color:'black'
            },
            {
              text: '确定',
              onClick(dialog, e) {
                qrcollect(riskInfo.confirmId);
              },
            }
          ]
        }).open();
    }else{
  props.f7router.navigate(`/collection/${tradeNo}`, { props: { redCodeParams: '' } })
    }
  }
}


//金额测算
const setMoneyCompute = (realAmount:string)=>{
    let params:any = {
        'amount': realAmount || data.chooseMeal.money,
        'payKey':data.params.payChannelType,
        'payeeKey':'BAL',
        'isFeeSeller':data.isFeeSeller,
        'calType':activeIndex?.value === '0' ?  '1' : activeIndex?.value === '1' ? '2' : '',
        'num':data.num?.key
    }
    params.bankCode = data.bankCode;
    moneyCompute(params).then((res)=>{
        if(res.code == '20000'){
            data.calculate = res.data;
        }else{
            data.calculate.totalAmount = '--'
            data.calculate.sellerAmount = '--'
            // showError(res.msg);
        }
    })
}


//切换手动
const toCollection =()=>{
    let params = JSON.stringify(data.params)
    console.log(params)
    // props.f7router.navigate(`/fixed/${props.collection}`,{reloadCurrent:true,animate:true})
    props.f7router.navigate(`/fixed/packageCategoryId`,{reloadCurrent:true,animate:true})
}


//支付通道列表
const list:any = ref([])

// const PayChannelTypeList = ()=>{
//     getPayChannelTypeList().then((res)=>{
//         list.value = res;
//     })
// }


const bankList = ref<any>([]);

const setBankList=(flag?:any)=>{
    getBankList({
        'amount':data.chooseMeal.money,
        'num': data.num?.key,
        'isFeeSeller':data.isFeeSeller           //isFeeSeller 1商户贴息  0顾客贴息
    }).then((res)=>{
        bankList.value = [];
        columns.value = [];
        forEach(res.bankList,(item)=>{
            item.down = false;
            bankList.value.push(item);
            columns.value.push(item);
        })
        if(flag){isOpened.value = true;}
    }).catch(()=>{
        bankList.value = [];
        columns.value = [];
    })
}


const isOpened = ref(false);
const searchBankName = ref('');
const bankName = ref('');
const columns = ref<any>([]);
const isOpenedBank=()=>{
    searchBankName.value = '';
    setBankList(true);
}


//银行搜索
const searchBankList=()=>{
    let list = []
        for(let i = 0;i<columns.value.length;i++){
            if(columns.value[i].bankName.includes(searchBankName.value)){
                list.push(columns.value[i])
            }
        }
        bankList.value = list;
}

const selectBank=(item:any)=>{
    data.provinceCode = item.provinceCode;
    data.mode = item.mode;
    bankName.value = item.bankName;
    data.bankCode = item.bankCode;
    isOpened.value = false;
}


const getActivityIndexPage=()=>{
    activityIndexPage().then((res:any)=>{
        data.selections = res;
        data.chooseChannelObj = data.selections.payInfo.find((item: { key: string; }) => item.key == data.params.payChannelType )
    })
}

const showError = (msg:any) => {
    window.f7.toast.show({
        position: 'center',
        text: msg,
        closeTimeout: 1500
    })
}

const tooltip = ref<HTMLElement>()

let activeIndex = ref('0');
//tabs左 0
async function tabsLeftClick() {
    console.log('左');
}

//tabs右 1
async function tabsRightClick() {
    console.log('右');
}

const onmyclick=(res:string)=>{
    // if(data.params.payChannelType == 'LIANLIAN'){
        data.bankCode = undefined;
        data.provinceCode = undefined;
        // setBankList();
    // }
    // if(data.params.payChannelType == 'LIANLIAN'){
    //     data.isFeeSeller = '1';
    // }else{
    //     data.isFeeSeller = res;
    // }
    activeIndex.value = res;
    if(res === '0'){
        tabsLeftClick();
    }else if(res === '1'){
        tabsRightClick();
    }
    setActivityCompute();
}


let showDiscount = ref(false);
let feeTypeSwitch = ref(0);
let discountAmount = ref('');
let usable = ref(false)

const setActivityCompute=(flag?:boolean)=>{
    let calType = activeIndex?.value === '0' ?  '1' : activeIndex?.value === '1' ? '2' : '';
    let params:any = {
        'amount':data.chooseMeal.money,
        'payKey':data.params.payChannelType,
        'payeeKey':'BAL',
        'isFeeSeller':data.isFeeSeller,
        'calType':calType,    //  用户实付 1   商户实收2
        'num':data.num?.key
    }
    params.bankCode = data.bankCode;
    activityCompute(params).then((res)=>{
        discountAmount.value = res.discountAmount;
        showDiscount.value = res.showDiscount;
        feeTypeSwitch.value = res.feeTypeSwitch;
        usable.value = res.usable;
        if(!flag){
            data.feeType = res.feeType === 0 ? false : true
            data.isFeeSeller = res.feeType;
        }
        if(res.usable){
            setMoneyCompute(res.realAmount);
        }else{
            data.calculate.totalAmount = '--'
            data.calculate.sellerAmount = '--'
        }
    })
}

//必填字段
const requireInfo:any = ref({})
const getMyCustomerInfoConfig =async()=>{
  let res = await getCustomerInfoConfig({})
  requireInfo.value = res
}

const toOldCollection = ()=>{
    props.f7router.navigate('/collection/new')
}

const PayChannelTypeList:any = ()=>{
    api.getPayChannelTypeList().then((res)=>{
      list.value = res;
      let payChannelType = res[0].payChannelType
      api.categoryList({'payChannelType':payChannelType}).then((val)=>{
        let packageCategoryId = val[0]?.packageCategoryId
        let name = val[0]?.name
        data.params = {'packageCategoryId':packageCategoryId,'payChannelType':payChannelType,'name':name}
      })
    })
}

const isEmpty = (str:any) =>{
    if(str == undefined || str == null || str == ''){
        return true
    }
    return false
}

onMounted( () => {
    getShowPackageEntrance()
    PayChannelTypeList()
    getMyCustomerInfoConfig()
    getActivityIndexPage()
    // await PayChannelTypeList();     //支付通道
    const tooltipInstance = window.f7.tooltip.create({
        targetEl: tooltip.value!,
        text: '商户是否承担分期手续费',
        trigger: 'click'
    })
    return () => {
        tooltipInstance.destroy()
    }
})

</script>

<style scoped>
.box {
    width: 100%;
    height: 100vh;
    background-color:#f8f8f8;
}
.header{
    font-family: PingFang SC;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 6px 0px #00000008;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #081828;
}
.round-card-header {
    position: relative;
    min-height: 40px;
    display: flex;
    align-items: flex-end;

    /* 往下叠加 */
    /* margin-top: 20px; */
}

.round-card-header::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 7px;
    height: 20px;
    background-color: var(--f7-theme-color);
    border-radius: 0px 3px 3px 0px;

    /* 往下叠加 */
    /* top: 0; */
}

.title {
    flex: 1;
    display: flex;
    align-items: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    margin-left: 16px;
    margin-right: auto;
    /* 一行省略 */
    /* white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; */

    

}
.content{
    padding: 16px;
    padding-top: 0;
    overflow: hidden;
    overflow-y: auto;
}
.content-item{
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #081828;
    padding: 8px 16px 8px 16px;
    border-radius: 6px;
    border: 0.5px;
    background-color: #FFFFFF;
    border: 0.5px solid #DFDFDF;
    margin-top: 16px;
}
.content-bottom{
    font-family: PingFang SC;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    margin-top: 2px;
    text-align: left;
    color: #666666;
}
.foot{
    display: flex;
    width: 100%;
    position: fixed;
    height: 75px;
    bottom: 15px;
    z-index: 1;
    background-color: #FFFFFF;
    border-bottom: 15px solid #f8f8f8;
}
.foot-left{
    width: 50%;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    padding: 8px 0;
    padding-left: 12px;
}
.foot-left div span{
    color: var(--f7-theme-color);
    margin-left: 8px;
}
.foot-right{
    width: 50%;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    background-color: var(--f7-theme-color);
    color: #FFFFFF;
}
.idFlag{
    border-color:var(--f7-ios-primary);
    background-color:var(--f7-md-surface-1)
}
.div-select{
    font-family: PingFang SC;
    border: 0.4px solid #9B9B9B;
    padding: 6px 12px;
    width: 156px;
    height: 32px;
    border-radius: 4px;
    text-align: left;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #C4C4C4;
}
.content-bank{
    font-family: PingFang SC;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.8px;
    padding: 16px;
    border-bottom: 1px solid #E6EBF2;
    text-align: left;
    color: #081828
}
.content-bank-right:last-child{
    margin-bottom: 0;
}
.content-bank-right{
    color: var(--f7-theme-color);
    border: 1px solid var(--f7-theme-color);
    border-radius: 4px;
    padding: 3px 8px;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    margin-bottom: 8px;
}
.headerInput{
    height: 48px;
    padding: 8px 16px 8px 16px;
    background-color: #FFFFFF;
}
.text-input{
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: space-between;
    background-color: #f8f8f8;
}
.icon-inp{
    display: flex;
    justify-content: space-evenly;
    background-color: #f8f8f8;
    border-radius: 8px 0 0 8px;
    width: 28px;
}
.input-right{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: var(--f7-theme-color);
    background-color: #f8f8f8;
    width: 84px;
    border-radius: 0 8px 8px 0;
}
.simSelect{
    height: 32px;
    background: #FFFFFF;
    border: 0.4px solid #9B9B9B;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 32px;
    line-height: 32px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--f7-label-text-color);
}
.down{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 24.8px;
    height: 24.8px;
    margin-right: 4px;
    text-align: center;
    color: var(--f7-theme-color)
}
.arrows{
    height: 10px;
    margin-top: 8px;
    margin-right: 8px;
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../../收款/活动收款/assets/arrows.svg);
    margin: auto;
    transform: rotate(90deg);
}

.icon-cell {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../活动收款/assets/arrows.svg);
    width: 6px;
    height: 8px;
    margin: auto;
}


.goCollection{
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background-color: var(--f7-md-surface-1);
  box-shadow: 0px 2px 6px 0px #00000008;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #1E1E1E;
}
</style>
