<template>
    
    <Page v-bind="$props" title="开通通道" navbar>
        <div class="groundFloor">
            <div class="box">
                <div class="title">开通支付通道</div>
                <div class="header">请选择您想开通的支付通道</div>
                <div v-for="item in list">
                    <div class="redLi" @click="navigate(item.channelKey)">{{ item.channelName }}</div>
                </div>
                <div class="bottom" @click="logout()">返回登录页</div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { Router } from 'framework7/types';
import { type } from 'os';
import Page from 'src/components/Pages/Page.vue';
import { defineProps, onMounted, reactive , ref} from 'vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import {checkOpenChannel} from "./api";
const login = sharedLogin

const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const data = reactive({

});

const list:any = ref([])

type NavigateProps = {
  "path": "",
  "query": {}
}
// const navigate = (value: NavigateProps) => {
//     props.f7router.navigate({
//         name: value.name,
//         path: value.path,
//         query: value.query
//     });
// }

const navigate = (channelKey:string) => {
    if(channelKey == 'ZFT'){
        props.f7router.navigate('/manualZFBEntry')  //支付宝
    }else if(channelKey == 'JDBT'){
        props.f7router.navigate('/manualEntry')     //京东
    }else if(channelKey == 'LIANLIAN'){
        props.f7router.navigate('/creditCard')      //信用卡
    }else if(channelKey == 'FUIOU'){
        props.f7router.navigate('/FUIOU')           //富友
    }
}

const logout = ()=>{
    login.setLogin(null)
}

const getCheckOpenChannel:any = async()=>{
    let params = {'code':login.invitationCode}
    let res = await checkOpenChannel(params)
    list.value = res.channelList
    
}

onMounted(()=>{
    getCheckOpenChannel();
})


</script>

<style scoped>
    .groundFloor{
        width:100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: relative;
        z-index: 999;
        display: flex;
        justify-content: space-evenly;
    }
    .box{
        position:absolute;
        top: 20%;
        width: 85.32%;
        z-index: 9999;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .box .title{
        padding: 32px 0px 16px 0px;
        font-family: PingFang SC;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        font-weight: 600;
        text-align: center;
        color: #000000E5;
    }
    .box .header{
        padding: 0px 24px 32px 24px;
        gap: 10px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #00000080;
    }
    .box .bottom{
        padding: 16px 0px 16px 0px;
        box-shadow: 0px 0.5px 0px 0px #0000001A inset;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        color: #303133;
    }
    .box .redLi{
        padding: 16px 0px 16px 0px;
        box-shadow: 0px 0.5px 0px 0px #0000001A inset;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 600;
        color: var(--f7-theme-color);
        /* color: #F06459; */
    }
    /* .groundFloor :deep(Page){
        background-color: saddlebrown;
    } */
</style>
