<template>
    <div class="mt-9 w-full">
        <form class="w-full">
            <RoundDesc class="m-4" name="手机号　" divide >
                <input class="self-stretch flex-1 w-0" type="text" placeholder="请输入手机号"
                       :value="inputValue()" @input="setDate($event.target.value)"/>
            </RoundDesc>
            <CaptchaInputCell label="验证码　" v-model="data.code" :fetchPhoneSms="fetchPhoneSms" />
        </form>
        <div class="px-6 flex justify-end">
            <f7Link class="text-[var(--f7-label-text-color)]" @click="emit('switch', 'password')">账号密码登录
                <img v-if="login.versions === 'ZanFu'" class="w-6 h-6" src="./assets/right-arrow-blue.svg" alt="">
                <img v-else class="w-6 h-6" src="./assets/right-arrow.svg" alt=""></f7Link>
        </div>
        <ActionButton fill class="m-4 mt-8" :action="loginAction">登录</ActionButton>
    </div>
</template>

<script setup lang="ts">
import { f7Link ,f7} from 'framework7-vue';
import {getColorTheme , checkOpenChannel} from '../api'
import RoundDesc from 'src/components/RoundControls/RoundDesc.vue';
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import CaptchaInputCell from 'src/pages/登录/components/CaptchaInputCell/index.vue';
import { reactive, computed, ComponentPublicInstance, ref , onMounted } from 'vue';
import type { LoginType } from './index.vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import * as api from "../../登录/api";
import { makeLoader } from 'src/Loader';
import ChooseUser from "./ChooseUser.vue";
import { getCurrentInstance } from 'vue'
const instance:any = getCurrentInstance();

const login = sharedLogin

const data = reactive({
    value: '',
    code: '',
    uid: '',
    username: ''
});

const emit = defineEmits<{
    (e: 'login'): void
    (e: 'switch', value: LoginType): void
}>()

function inputValue() {
    if (data.value) {
        return data.value
    } else {
        return ''
    }
}

function setDate(v: string) {
    if (v) {
        if(v.length===11) {
            data.value = v
            getUserList(v)
        }
    }
}

//获取账号列表
const userList = makeLoader(api.getUserList)
    .withIndicator()
    .showError()

async function getUserList(v) {
    let arr:any = []
    const res = await userList.load(v)
    res.forEach(item => {
        arr.push({
            uid: item.uid,
            text: item.accountName,
            lastLoginFlag: item.lastLoginFlag,
            onClick: (dialog, index) => {
                data.uid = item.uid
                data.username = item.accountName
            }
        })
    })
    window.f7.dialog.create(
        {
            title: '登录账号',
            buttons: arr,
            verticalButtons: true,
        }).open()
}

//获取手机验证码
async function fetchPhoneSms() {
    return await bindSms.load(data.value).then(() => {
        window.f7.toast.show({
            position: 'center',
            text: '验证码发送成功',
            closeTimeout: 3000
        })
        return true
    }).catch(() => {
        return false
    })
}

const bindSms = makeLoader(api.getLoginPhoneSms)
    .withIndicator()
    .showError()

//登录
const loader = makeLoader(api.smslogin)
    .onLoad(login.setLogin.bind(login))
    .showError()
    .showSuccess('登录成功')
    .reactive()

function loginAction() {
    return login.askAgreePrivacyIfNeeds()
        .then(() => loader.load(data.value, data.code, data.uid,login.code(),login.appId).then(()=>{
            showPackageEntrance();
            if(login.versions === 'ZanFu'){
                f7.setColorTheme('#5575FF')
                localStorage.setItem("color1",JSON.stringify('#5575FF'));
            }else{
                themeColor();
            }
            getOpenChannel();
            // if(login.code()){
            //     getOpenChannel();
            // }
        }))
}

const themeColor = async() => {
    let res = await getColorTheme({'type':1});
    f7.setColorTheme(res.color);
    localStorage.setItem("color1",JSON.stringify(res.color));
}

const getOpenChannel = async()=>{
    let params = {'code':login.invitationCode}
    let res = await checkOpenChannel(params);
    let pageType = '-1';
    if(res.openAccount){
        if(!res.auditChannel){  
            pageType = '0';
        }else{
            pageType = res.auditChannel;    //-1首页 0选择通道  1支付宝 2微信 3京东 4信用卡 5扫呗 8富友
        }
    }else{
        pageType = '-1';
    }
    instance?.proxy?.$bus.emit('pageType',pageType)
}


const showPackageEntrance = async ()=>{
    let res = await api.showPackageEntrance();
    localStorage.setItem("isDefault",JSON.stringify(res));
    let isDefault = res.isDefault ? 1 : 0
    instance?.proxy?.$bus.emit('isDefault',isDefault)
}


onMounted(()=>{
    showPackageEntrance();
    getOpenChannel();
    if(login.versions === 'ZanFu'){
        f7.setColorTheme('#5575FF')
    }else{
        if(localStorage.getItem("color1") && localStorage.getItem("color1") != ''  && localStorage.getItem("color1") != '#5575FF' ){
            let color:any = localStorage.getItem("color1")
            let color2 = JSON.parse(color)
            f7.setColorTheme(color2)
        }else{
            f7.setColorTheme('#DB3022')
        }
    }
})
</script>

<style scoped>

</style>
