<template>
    <div>
        <div class="header">
            <div class="flex items-center">
                <img class="w-4 h-4" v-if="payChannelType == 'ALI'" src="../assets/hua-bei.svg" alt="">
                <img class="w-4 h-4" v-if="payChannelType == 'LIANLIAN'" src="../assets/xyk.svg" alt="">
                <img class="w-4 h-4" v-if="payChannelType == 'JIOU'" src="../assets/baitiao.svg" alt="">
                <PlainSelect>
                    <select v-model="payChannelType" @change="switchPayChannel">
                        <option v-for="item in list" :value="item.payChannelType">{{ item.desc }}</option>
                    </select>
                </PlainSelect>
            </div><span style="color:#DCDCDC">|</span>
            <div class="flex items-center" @click="onClick()">
                <img v-if="login.versions === 'ZanFu'" class="w-4 h-4 mr-1" src="../assets/switchover-ZF.png" alt="">
                <img v-else class="w-4 h-4 mr-1" src="../assets/switchover.svg" alt="">
                {{ text }}
            </div>
        </div>
   </div>
</template>

<script setup lang="ts">
import PlainSelect from 'src/components/Inputs/PlainSelect.vue';
import { ref } from 'vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';

export type ChannelType = {
    payChannelType: string
    desc: string
}

const login = sharedLogin
const props = defineProps<{
    list:ChannelType[]
    text:string
    payChannelType:string
}>();

const emit = defineEmits<{
(event: 'HeaderBarEmit', value?: string): void
(event: 'HeaderPayChannelSelect', value?: string): void
}>();

const onClick=()=>{
    emit('HeaderBarEmit');
}

const payChannelType = ref(props.payChannelType);

const switchPayChannel=()=>{
    console.log(payChannelType.value);
    
    emit('HeaderPayChannelSelect',payChannelType.value);
}


defineExpose({
    
})




</script>

<style scoped>
.header{
    font-family: PingFang SC;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 6px 0px #00000008;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #081828;
}
.round-card-header {
    position: relative;
    min-height: 40px;
    display: flex;
    align-items: flex-end;

    /* 往下叠加 */
    /* margin-top: 20px; */
}

.round-card-header::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 7px;
    height: 20px;
    background-color: var(--f7-theme-color);
    border-radius: 0px 3px 3px 0px;

    /* 往下叠加 */
    /* top: 0; */
}

.icon-cell {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../assets/switchover.svg);
    width: 6px;
    height: 8px;
    margin: auto;
}
</style>
