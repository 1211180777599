<template>
    <div>
        <div class="foot h-full">
            <div class="foot-left">
                <div>用户实付<span>￥{{ totalAmount || '-' }}</span></div>
                <div style="margin-top: 4px;">商家实收<span>￥{{ sellerAmount || '-' }}</span></div>
            </div>
            <div class="foot-right" @click="throttleOnClick">
                二维码收款
            </div>
        </div>
        <div class="bottomDiv"></div>
   </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import _ from "lodash";
const props = defineProps<{
    totalAmount:string
    sellerAmount:string
}>();

const emit = defineEmits<{
    (event: 'TabBarEmit', value?: string): void
}>();

const throttleOnClick = _.throttle(()=>{
    onClick()
  },2000, { 'trailing': false })


const onClick=()=>{
    emit('TabBarEmit');
}

defineExpose({
    
})




</script>

<style scoped>
.foot{
    display: flex;
    width: 100%;
    /* position: fixed; */
    height: 75px;
    bottom: 15px;
    z-index: 1;
    background-color: #FFFFFF;
    border-bottom: 15px solid #f8f8f8;
}
.foot-left{
    width: 50%;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    padding: 8px 0;
    padding-left: 12px;
}
.foot-left div span{
    color: var(--f7-theme-color);
    margin-left: 8px;
}
.foot-right{
    width: 50%;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    background-color: var(--f7-theme-color);
    color: #FFFFFF;
}
.bottomDiv{
    position: fixed;
    bottom: 0;
    background-color: #f8f8f8;
    width: 100%;
    height: 15px;
}
</style>
