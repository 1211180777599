<template>
    <div class="flex h-9 tabs">
        <img v-if="active === '0'" style="left: 0;" src="../assets/tab-left.png" alt="" @click="leftClick">
        <div class="tab-left" @click="leftClick">
            <div :class="active === '0' ? 'activeTextColor' : ''">{{ leftText }}</div>
        </div>
        <img v-if="active === '1'" style="right: 0;" src="../assets/tab-right.png" alt="" @click="rightClick">
        <div class="tab-right" @click="rightClick">
            <div  :class="active === '1' ? 'activeTextColor' : ''">{{ rightText }}</div>
        </div>
   </div>
</template>

<script setup lang="ts">
import { makeLoader } from 'src/Loader';
import { ref } from 'vue';
const props = defineProps<{
    leftText:string
    rightText:string
    active: string
}>();

const emit = defineEmits<{
(event: 'tabsEmit', value: string): void
}>();

const active = ref(props.active);

const leftClick=()=>{
    active.value = '0';
    emit('tabsEmit',active.value);
}
const rightClick=()=>{
    active.value = '1';
    emit('tabsEmit',active.value);
}

defineExpose({


})




</script>

<style scoped>
.tabs{
    width: 100%;
    background-color: var(--f7-md-surface-1);
    border-radius: 8px 8px 0 0;
    position: relative;
}
.tab-left{
    color: #081828;
    width: 50%;
    line-height: 36px;
    position: relative;
}
.tabs img{
    width: 55%;
    height: 36px;
    position: absolute
}
.tab-left div{
    width: 100%;
    text-align: center;
    position: absolute
}
.tab-right{
    color: #081828;
    width: 50%;
    line-height: 36px;
    position: relative;
}
.tab-right div{
    width: 100%;
    text-align: center;
    position: absolute
}
.activeTextColor{
    color: var(--f7-theme-color);
}
</style>
