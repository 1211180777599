import { resultData , http } from "src/http";
import { OrderDetail, StoreDetail } from "../订单/api";


export function createOrder() {
  return resultData.post<OrderDetail>('/jhserver/order/app/v1/djQr');
}

export type RedPacketCodeParams = {
  amount: string
  insOrBal: string
  key: string
  merchantNo: string
  num: number
}

export type StoreParams = {
  address: string,
  city: string,
  cityCode: string,
  district: string,
  districtCode: string,
  id: number,
  innerImg: string,
  entryImg: string,
  facadeImg: string,
  principalName: string,
  principalPhone: string,
  province: string,
  provinceCode: string,
  street: string,
  streetCode: string
}

export function queryRedPacketCodeState(params: RedPacketCodeParams) {
  return resultData.post<boolean>('/jhserver/order/app/v1/queryRedPacketCodeState', params);
}

export function redPacketCodeUrl(params: RedPacketCodeParams) {
  return resultData.post<any>('/jhserver/order/app/v1/redPacketCodeUrl', params);
}

export function getCurrDetail() {
  return resultData.post<StoreDetail>('/jhserver/store/app/v1/getCurrDetail');
}

export function editCurrStore(params: StoreParams) {
  return resultData.post<StoreDetail>('/jhserver/store/app/v1/editCurrStore', params);
}

export function payResult(params: any) {
  return resultData.post<any>('/jhserver/order/app/v2/payResult', params);
}

//办单红包奖励
export function computeRedPacket(params: any) {
  return resultData.post<any>('/jhserver/marketingactivity/app/v1/computeRedPacket', params);
}

//活动红包弹窗
export function getActivityRedPacketShow() {
  return resultData.post('/jhserver/marketingactivity/app/v1/show')
}

//是否展示套餐入口
export function showPackageEntrance() {
  return resultData.post<any>('/jhserver/package/template/app/v1/showPackageEntrance');
}

//支付通道列表
export function getPayChannelTypeList() {
  return resultData.post<any>('/jhserver/package/template/app/v1/getPayChannelTypeList');
}

//分类列表
export function categoryList(params: any) {
  return resultData.post<any>('/jhserver/package/category/app/v1/list', params);
}

//套餐列表
export function setMealList(params: any) {
  return resultData.post<any>('/jhserver/package/template/app/v1/list', params);
}

//金额测算
export function moneyCompute(params: any) {
  return http.json.post<any>('/jhserver/order/app/v1/compute', params);
}

//分期套餐支持银行
export function getSupportBankList() {
  return http.json.post<any>('/jhserver/order/app/v2/getSupportBankList');
}

//活动首页
export function activityIndexPage() {
  return resultData.post<OrderDetail>('/jhserver/order/app/v1/activityIndexPage');
}

//银行列表
export function getBankList(params: any) {
  return resultData.post<any>('/jhserver/order/app/v2/getUserBankList', params);
}

//活动收款金额测算
export function activityCompute(params: any) {
  return resultData.post<any>('/jhserver/order/app/v1/activityCompute', params);
}


//查询收款客户信息必填字段
export function getCustomerInfoConfig(params: any) {
  return resultData.post<any>('/jhserver/order/app/v2/getCustomerInfoConfig', params);
}

export type StoreDetail = {
  "address": string,
  "city": string,
  "cityCode": string,
  "district": string,
  "districtCode": string,
  "id": number,
  "innerImg": string,
  "entryImg": string,
  "facadeImg": string,
  "principalName": string,
  "principalPhone": string,
  "province": string,
  "provinceCode": string,
  "street": string,
  "streetCode": string,
}