<template>
  <Page v-bind="$props" title="收款" navbar :tab-root="role == 3 || role == 6 ? false : isDefault ? false : true " @will-appear="initData">
    <template #nav-right>
      <ExitMenuLink />
    </template>
    <f7Toolbar class="flex-none">
      <div class="flex justify-between px-2 w-full">
        <span>门店名称</span>
        <span class="font-medium">{{ order.value?.storeName ?? '...' }}</span>
      </div>
    </f7Toolbar>
    <div class="overflow-y-auto">
      <div class="flex justify-between mx-4 mt-4 goCollection" v-if="ShowPackageEntranceFlag && payChannelListShow && !isDefault">
        <div>现已开启活动收款，快来办单吧~</div>
        <div class="flex items-center" style="color: var(--f7-theme-color);" @click="PayChannelDialog()">去收款&nbsp;
          <div class="icon-cell"></div>
        </div>
      </div>
      <div v-if="order.value?.showDialog == false">
        <PayeeInfo v-if="order.value" class="mx-4 mt-4" :order="order.value" v-model:selections="data.selections" :amount1="data.amount">
          <template #支付信息>
            <div class="flex justify-between items-center mb-3">
              <span class="flex-none">收款金额</span>
              <RedEnvelopeSheet ref="sheetRef" class="ml-2 mr-auto" :params="redCodeParams"
                                @update:amount="data.editingAmount = data.amount = $event" v-slot="{ open }">
                <img src="./assets/repack.svg" alt="" @click="open">
              </RedEnvelopeSheet>
              <span class="flex items-center border-b border-solid border-[#DFDFDFCC]">
                <span class="mr-1">￥</span>
                <input class="h-8 w-[120px]" type="number" placeholder="请输入收款金额" v-model="data.editingAmount"
                       @blur="data.amount = data.editingAmount">
              </span>
            </div>
          </template>
        </PayeeInfo>
        <RoundCard class="mx-4 mt-4">
          <RoundCardHeader title="其他信息"></RoundCardHeader>
          <div class="p-4">
            <div class="flex justify-between items-center">
              <span class="flex-none">客户姓名 <span v-if="requireInfo?.customerName == 1" style="color: red;">*</span></span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入客户姓名" v-model="data.customerName">
              </span>
            </div>
            <div class="flex justify-between items-center mt-3">
              <span class="flex-none">客户手机号<span v-if="requireInfo?.customerPhone == 1 || data.selections.mode == 1" style="color: red;">*</span></span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入客户手机号" v-model="data.customerPhone">
              </span>
            </div>
            <div class="flex justify-between items-center mt-3">
              <span class="flex-none">营业员工号<span v-if="requireInfo?.outCashierNo == 1" style="color: red;">*</span></span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入营业员工号" v-model="data.outCashierNo">
              </span>
            </div>
            <!-- <div class="flex justify-between items-center mt-3">
              <span class="flex-none">商品品牌</span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入商品品牌" v-model="data.productName">
              </span>
            </div> -->
            <div class="flex justify-between items-center mt-3">
                <span class="flex-none">商品品牌<span v-if="requireInfo?.productName == 1" style="color: red;">*</span></span>
                <span class="w-[190px] border-b border-solid border-[#DFDFDFCC]">
                  <SelectStyle class="w-[180px] float-right">
                    <select class="w-[180px]" style="border: none;text-align: right !important;" v-model="data.productName">
                        <option :value="undefined || ''">请选择</option>
                        <template v-for="item in data.productNameList">
                            <option :value="item">{{ item }}</option>
                        </template>
                    </select>
                </SelectStyle>
                </span>
                
            </div>
            <div class="flex justify-between items-center mt-3">
              <span class="flex-none">商品型号<span v-if="requireInfo?.productSpec == 1" style="color: red;">*</span></span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入商品型号" v-model="data.productSpec">
              </span>
            </div>
            <div class="flex justify-between items-center mt-3">
              <span class="flex-none">IMEI<span v-if="requireInfo?.imei == 1" style="color: red;">*</span></span>
              <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入IMEI" v-model="data.imei">
                <IMEIInput class="ml-2" v-model:imei="data.imei"></IMEIInput>
              </span>
            </div>
          </div>
        </RoundCard>
        
        
        <div class="TiSi" style="margin-top: 5px;">
          <img class="w-[16px] h-[16px]" style="margin:0 0 4px 0;" src="../../信用卡自主进件/步骤/assets/Vector.png" alt="">
          <div>信息带&nbsp;<span style="color: red;">*</span>&nbsp;为必填项，其他为选填项</div>
        </div>
        <ActionButton class="m-4" fill :action="throttleQr">
          <img src="./assets/qrcode.svg" alt=""> 二维码收款
        </ActionButton>
      </div>

      <div v-else>
        <div class="info" v-if="order.value?.state == 2">
          <img rc="./assets/tip.svg" alt="" style="width: 111px">
          <p class="text-sm">您还未完善门店信息哦~</p>
          <ActionButton class="w-[164px] h-[48px]" fill :action="toImproveInformation">
            点击完善
          </ActionButton>
        </div>
        <div class="info" v-else-if="order.value?.state == 0">
          <img rc="./assets/warn.svg" alt="" style="width: 72px">
          <p class="text-sm">门店信息正在审核中
            请耐心等待~~</p>
        </div>
        <div class="info" v-else-if="order.value?.state == -1">
          <img src="./assets/warn.svg" alt="" style="width: 72px">
          <p class="text-sm">审核不通过~</p>
          <p class="text-xs" style="color: var(--f7-theme-color);margin-top: 0">原因：{{ order.value?.reason }}</p>
          <ActionButton class="w-[164px] h-[48px]" fill :action="toImproveInformation">
            重新提交
          </ActionButton>
        </div>

        <div v-else-if="order.value?.state == 5">
          <div class="mx-4 mt-4 flex items-start"  v-if="order.value?.reason">
            <img src="./assets/error-circle.svg" class="flex-none" />
            <div style="line-height: 15px;font-size: 14px;color: var(--f7-theme-color);margin-left: 5px;"> 驳回原因：{{ order.value?.reason }} </div>
          </div>
          <RoundCard class="mx-4 mt-4">
            <RoundCardHeader title="完善信息"></RoundCardHeader>
            <div class="p-4">
              <div class="flex justify-between items-center">
                <span class="flex-none">联系人</span>
                <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                  <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入联系人姓名"
                         v-model="storeInfo.principalName">
                </span>
              </div>
            </div>
            <div class="p-4">
              <div class="flex justify-between items-center">
                <span class="flex-none">联系人手机号</span>
                <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                  <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入联系人手机号"
                         v-model="storeInfo.principalPhone">
                </span>
              </div>
            </div>
            <div class="p-4">
              <div class="flex justify-between items-center">
                <span class="flex-none">门店地区</span>
                <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                  <DistrictPicker v-slot:default="{ open, close }" v-model:value="districts">
                    <input class="w-full h-8 text-right" type="text" :value="districts.map(d => d.name).join(',')"
                           readonly placeholder="请选择们门店地址" @click="open">
                  </DistrictPicker>
                </span>
              </div>
            </div>
            <div class="p-4">
              <div class="flex justify-between items-center">
                <span class="flex-none">详细地址</span>
                <span class="w-[190px] flex items-center border-b border-solid border-[#DFDFDFCC]">
                  <input class="flex-1 w-0 h-8 text-right" type="text" placeholder="请输入详细地址" v-model="storeInfo.address">
                </span>
              </div>
            </div>
          </RoundCard>
          <RoundCard class="mx-4 my-4">
            <RoundCardHeader title="上传照片"></RoundCardHeader>
            <div class="px-4">
              <p>门头照片</p>
              <div class="flex justify-between h-[96px]">
                <img class="w-[152px] h-[96px]" src="./assets/facadeImg.svg" alt="">
                <ImageUploader class="w-[152px] h-[96px]" v-model:value="storeInfo.facadeImg">
                  <div class="uploader w-full h-full border border-dashed border-[#E6EBF2]"></div>
                </ImageUploader>
              </div>
            </div>
            <div class="px-4">
              <p>店内照片</p>
              <div class="flex justify-between h-[96px]">
                <img class="w-[152px] h-[96px]" src="./assets/innerImg.svg" alt="">
                <ImageUploader class="w-[152px] h-[96px]" v-model:value="storeInfo.innerImg">
                  <div class="uploader w-full h-full border border-dashed border-[#E6EBF2]"></div>
                </ImageUploader>
              </div>
            </div>
            <div class="px-4 pb-4">
              <p>收银台照片</p>
              <div class="flex justify-between h-[96px]">
                <img class="w-[152px] h-[96px]" src="./assets/entryImg.svg" alt="">
                <ImageUploader class="w-[152px] h-[96px]" v-model:value="storeInfo.entryImg">
                  <div class="uploader w-full h-full border border-dashed border-[#E6EBF2]"></div>
                </ImageUploader>
              </div>
            </div>
          </RoundCard>
          <ActionButton class="m-4" fill :action="submittals">
            确定提交
          </ActionButton>
        </div>
      </div>


      <!-- 办单红包弹窗 -->
      <div v-if="ActivityShow">
            <div class="warp">
                <div class="rect1" @click.stop="toActivityDetail()">
                    <div style="position: absolute;bottom: -50px;">
                        <img @click.stop="ActivityShow = false" src="../../首页/assets/fork.svg" alt="">
                    </div>
                </div>
            </div>
       </div>

      <div class="warp" @click="PayChannelFlag = false" v-if="PayChannelFlag">
        <div class="box" @click.stop="">
          <div class="title">活动收款通道</div>
          <div class="header">请选择收款通道</div>
          <div v-for="item in payChannelList">
            <div class="redLi" @click="props.f7router.navigate(`/classify/${item.payChannelType}`),PayChannelFlag = false">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <!-- <div @click="navigate('/creditCard/StoreNew')">新入驻</div>
      <div @click="navigate('/creditCard/StoreEnter')">已入驻</div>
      <div @click="navigate('/creditCard')">111</div> -->
      <!-- <div @click="navigate('/collection2/succeedContent1')"></div> -->
    </div>
  </Page>
</template>

<script setup lang="ts">
import { f7Toolbar } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import { makeLoader } from 'src/Loader';
import PayeeInfo from 'src/pages/订单/订单详情/PayeeInfo.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import * as api from '../api';
import * as orderApi from '../../订单/api';
import IMEIInput from './IMEIInput.vue';
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import ExitMenuLink from 'src/pages/登录/登录/ExitMenuLink.vue';
import RedEnvelopeSheet from '../白条红包码/RedEnvelopeSheet.vue';
import DistrictPicker, { DistrictItem } from 'src/components/DistrictPicker/DistrictPicker.vue';
import ImageUploader from 'src/components/Uploader/ImageUploader.vue';
import SelectStyle from 'src/components/Inputs/BoardSelect.vue';
import _, { forEach } from 'lodash';
const props = defineProps<{
  f7router: Router.Router
  f7route: Router.Route
}>();

const role = ref(JSON.parse(localStorage.getItem("user"))?.accountType)

const sheetRef = ref<InstanceType<typeof RedEnvelopeSheet>>()

const order:any = makeLoader(api.createOrder).reactive()
order.load()

const navigate = (url:string) => {
    props.f7router.navigate(url)
}

const data = reactive({
  productNameList:['华为（HUAWEI）',
  '苹果（Apple）',
  '荣耀（HONOR）',
  'OPPO','vivo','小米（XIAOMI）','红米（Redmi）','魅族（MEIZU）','一加（ONEPLUS）','真我（realme）','中兴（ZTE）','IQOO','努比亚（nubia）','红魔（Redmagic）','Hi nova','其他品牌（商品型号填写)'],
  customerName: "",
  customerPhone: "",
  outCashierNo:"",
  productName: "",
  productSpec: "",
  imei: "",
  amount: "",
  editingAmount: "",
  selections: {
    payee: undefined as orderApi.PayeeInfo | undefined,
    pay: undefined as orderApi.PayInfo | undefined,
    num: undefined as orderApi.NumInfo | undefined,
    condition: undefined as boolean | undefined,
    bankCode:undefined as string | undefined,
    mode:undefined as number | undefined,
    provinceCode:undefined as string | undefined,
  },
  money: ""
})

const initData = function(){
  data.customerName =""
  data.customerPhone = ""
  data.outCashierNo = ""
  data.productName = ""
  data.productSpec = ""
  data.imei = ""
  data.amount =  ""
  data.editingAmount = ""
  data.selections = {
    payee: undefined as orderApi.PayeeInfo | undefined,
    pay: undefined as orderApi.PayInfo | undefined,
    num: undefined as orderApi.NumInfo | undefined,
    condition: undefined as boolean | undefined,
    bankCode:undefined as string | undefined,
    mode:undefined as number | undefined,
    provinceCode:undefined as string | undefined,
  },
  data.money = ""
}


const districts = computed({
  get(): DistrictItem[] {
    const result: DistrictItem[] = []
    if (storeInfo.province) {
      result.push({
        name: storeInfo.province,
        code: storeInfo.provinceCode
      })
    }
    if (storeInfo.city) {
      result.push({
        name: storeInfo.city,
        code: storeInfo.cityCode
      })
    }
    if (storeInfo.district) {
      result.push({
        name: storeInfo.district,
        code: storeInfo.districtCode
      })
    }
    if (storeInfo.street) {
      result.push({
        name: storeInfo.street,
        code: storeInfo.streetCode
      })
    }
    return result
  },
  set(value) {
    if (value[0]) {
      storeInfo.province = value[0].name
      storeInfo.provinceCode = value[0].code
    } else {
      storeInfo.province = ""
      storeInfo.provinceCode = ""
    }
    if (value[1]) {
      storeInfo.city = value[1].name
      storeInfo.cityCode = value[1].code
    } else {
      storeInfo.city = ""
      storeInfo.cityCode = ""
    }
    if (value[2]) {
      storeInfo.district = value[2].name
      storeInfo.districtCode = value[2].code
    } else {
      storeInfo.district = ""
      storeInfo.districtCode = ""
    }
    if (value[3]) {
      storeInfo.street = value[3].name
      storeInfo.streetCode = value[3].code
    } else {
      storeInfo.street = ""
      storeInfo.streetCode = ""
    }
  }
})

const storeInfo = reactive({
  address: "",
  city: "",
  cityCode: "",
  district: "",
  districtCode: "",
  entryImg: "",
  facadeImg: "",
  innerImg: "",
  principalName: "",
  principalPhone: "",
  province: "",
  provinceCode: "",
  street: "",
  streetCode: "",
  id: 0,
  bankCode:'',
  mode:'',
})

const redCodeParams = computed((): api.RedPacketCodeParams | undefined => {
  if (data.amount === "") return undefined
  if (data.selections.payee === undefined) return undefined
  if (data.selections.pay === undefined) return undefined
  if (data.selections.num === undefined) return undefined
  if (order.value === undefined) return undefined
  return {
    amount: data.amount,
    key: data.selections.pay.key,
    insOrBal: data.selections.payee.key,
    num: parseInt(data.selections.num.key),
    merchantNo: order.value.merchantNo,
  }
})

const qr = makeLoader(orderApi.qrcollection)
  .mapParams((confirmId) => [{
    confirmId,
    payeeKey: data.selections.payee?.key,
    payKey: data.selections.pay?.key,
    num: data.selections.num?.key,
    isFeeSeller: data.selections?.condition ? 1 : 0,
    customerName: data.customerName,
    customerPhone: data.customerPhone,
    outCashierNo:data.outCashierNo,
    productName: data.productName,
    productSpec: data.productSpec,
    imei: data.imei,
    amount: data.amount,
    bankCode:data.selections.bankCode,
    mode:data.selections.mode,
    provinceCode:data.selections.provinceCode
  }])
  .withIndicator()
  .showError()

const throttleQr = _.throttle(()=>{
    qrcollectionWithAsk()
  },2000, { 'trailing': false })


async function qrcollectionWithAsk() {
  // if((order.value?.requireOutCashierNo || data.selections.mode == 1) && !data.customerPhone){
  //   showError('请输入手机号');
  //   return;
  // }
  // if(order.value?.requireOutCashierNo && !data.outCashierNo){
  //   showError('请输入营业员工号');
  //   return;
  // }
  // await qr.load() // 验证请求参数
  const sheet = sheetRef.value!
  await sheet.askUseIfNeeds()
  return qrcollect()
}

async function qrcollect(confirmId?:any) {
  const { tradeNo , riskInfo }:any = await qr.load(confirmId)
  if(isEmpty(riskInfo)){
    props.f7router.navigate(`/collection/${tradeNo}`, { props: { redCodeParams: redCodeParams.value } })
    return
  }
  if( !riskInfo.pass ){
    showError(riskInfo.riskMsg);
    return 
  }else{
    
    if( riskInfo.confirm ){
      window.f7.dialog.create({
        title: '风控提示',
        text: riskInfo.riskMsg,
        buttons: [
            {
              text: '取消',
              onClick(dialog, e) {
                  //   console.log('取消');
              },
              color:'black'
            },
            {
              text: '确定',
              onClick(dialog, e) {
                qrcollect(riskInfo.confirmId);
              },
            }
          ]
        }).open();
      // window.f7.dialog.alert(riskInfo.riskMsg,' ')
    }else{
      // if(!riskInfo.confirm && riskInfo.pass){
        props.f7router.navigate(`/collection/${tradeNo}`, { props: { redCodeParams: redCodeParams.value } })
      // }
    }
  }
}

async function toImproveInformation() {
  order.value.state = 5

  await storeDetail.load()
  storeInfo.id = storeDetail.value?.id
  storeInfo.address = storeDetail.value?.address
  storeInfo.city = storeDetail.value?.city
  storeInfo.cityCode = storeDetail.value?.cityCode
  storeInfo.district = storeDetail.value?.district
  storeInfo.districtCode = storeDetail.value?.districtCode
  storeInfo.entryImg = storeDetail.value?.entryImg
  storeInfo.facadeImg = storeDetail.value?.facadeImg
  storeInfo.innerImg = storeDetail.value?.innerImg
  storeInfo.principalName = storeDetail.value?.principalName
  storeInfo.principalPhone = storeDetail.value?.principalPhone
  storeInfo.province = storeDetail.value?.province
  storeInfo.provinceCode = storeDetail.value?.provinceCode
  storeInfo.street = storeDetail.value?.street
  storeInfo.streetCode = storeDetail.value?.streetCode
}
const storeDetail = makeLoader(api.getCurrDetail)
  .withIndicator()
  .showError()
  .reactive()

const edit = makeLoader(api.editCurrStore)
  .withIndicator()
  .showError()
  .onLoad(() => order.load())

async function submittals() {
  const result = await edit.load({
    address: storeInfo?.address,
    city: storeInfo?.city,
    cityCode: storeInfo?.cityCode,
    district: storeInfo?.district,
    districtCode: storeInfo?.districtCode,
    entryImg: storeInfo?.entryImg,
    facadeImg: storeInfo?.facadeImg,
    innerImg: storeInfo?.innerImg,
    principalName: storeInfo?.principalName,
    principalPhone: storeInfo?.principalPhone,
    province: storeInfo?.province,
    provinceCode: storeInfo?.provinceCode,
    street: storeInfo?.street,
    streetCode: storeInfo?.streetCode,
    id: storeInfo?.id
  })
}

const ShowPackageEntranceFlag = ref();
const isDefault = ref(JSON.parse(localStorage.getItem("isDefault"))?.isDefault)
//是否展示套餐入口
const getShowPackageEntrance =()=>{
  api.showPackageEntrance().then((res)=>{
    ShowPackageEntranceFlag.value = res.show;
  })
}

const payChannelList:any = ref([])
const payChannelListShow:any = ref(false)
const getChannelTypeListShow =async ()=>{
  let res = await api.getPayChannelTypeList();
  if(res.length > 0){
    payChannelListShow.value = true;
  }else{
    payChannelListShow.value = false;
  }
}
//通道选择
// const PayChannelTypeList:any = ()=>{
//     api.getPayChannelTypeList().then((res)=>{
//       let payChannelType = res[0].payChannelType
//       api.categoryList({'payChannelType':payChannelType}).then((val)=>{
//           let packageCategoryId = val[0]?.packageCategoryId || ''
//           let name = val[0]?.name || ''
//           let params = JSON.stringify({'packageCategoryId':packageCategoryId,'payChannelType':payChannelType,'name':name});
//           props.f7router.navigate(`/activity/${params}`)
//       })
//       payChannelList.value = res;
//     })
// }

const PayChannelFlag = ref(false);

const PayChannelDialog =()=>{
  props.f7router.navigate(`/activity/collection`)
  // PayChannelTypeList()
  // PayChannelFlag.value = true;
}


//去活动详情
const toActivityDetail=()=>{
    ActivityShow.value = false;
    navigate('/redActivity/detail');
}

const ActivityShow = ref(false);
//活动红包弹窗
const ActivityRedPacketShow=async()=>{
    let res = await api.getActivityRedPacketShow()
    ActivityShow.value = res.show;
}

//必填字段
const requireInfo:any = ref({})
const getCustomerInfoConfig =async()=>{
  let res = await api.getCustomerInfoConfig({})
  requireInfo.value = res
}

const showError=(msg:string)=>{
    window.f7.toast.show({
        position: 'center',
        text: msg,
        closeTimeout: 3000
    })
}

const isEmpty = (str:any) =>{
    if(str == undefined || str == null || str == ''){
        return true
    }
    return false
}

onMounted(async()=>{
  await getCustomerInfoConfig()
  await getChannelTypeListShow();
  await getShowPackageEntrance();
  if(role.value == 3 || role.value == 6 ? false : true){
    await ActivityRedPacketShow();
  }
})

</script>

<style scoped>
.toolbar {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  color: #1E1E1E;
}

.info {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.uploader {
  background: url(./assets/plus.svg) no-repeat center;
}

.goCollection{
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background-color: var(--f7-md-surface-1);
  box-shadow: 0px 2px 6px 0px #00000008;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #1E1E1E;
}
.warp {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .box{
        position:absolute;
        width: 70%;
        z-index: 9999;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .box .title{
        padding: 32px 0px 10px 0px;
        font-family: PingFang SC;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        font-weight: 600;
        text-align: center;
        color: #000000E5;
    }
    .box .header{
        padding: 0px 24px 12px 24px;
        gap: 10px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #00000080;
    }
    .box .redLi{
        padding: 16px 0px 16px 0px;
        box-shadow: 0px 0.5px 0px 0px #0000001A inset;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 600;
        color: var(--f7-theme-color);
    }
    .rect1 {
    position: fixed;
    top: 192px;
    width: 286px;
    height: 291px;
    border-radius: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-image: url(../../首页/assets/bandan-bg.svg);
    background-size: cover;
}
.icon-cell {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../活动收款/assets/arrows.svg);
    width: 6px;
    height: 8px;
    margin: auto;
}

.TiSi{
    color: #FF7733;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin:0 16px 0 16px;
    display: flex;
}
</style>
